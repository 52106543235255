import { useEffect } from "react";
import styled from "styled-components";

const Policy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Root>
      <div>
        <h1>폼체커 개인정보 처리방침</h1>
        <div>
          ㈜팀엘리시움(이하 '회사')은 개인정보 보호법 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을
          신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 처리방침을 수립하여 공개합니다.
        </div>
        <h3>제1조 처리하는 개인정보 항목</h3>
        <div>회사는 다음의 개인정보 항목을 처리하고 있습니다.</div>
        <div>① 제품 문의 – 성명, 전화번호, 직함, 기관, 회사명</div>
        <h3>제2조 개인정보의 처리 목적</h3>
        <div>
          회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는
          이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한
          조치를 이행할 예정입니다.
        </div>
        <div>① 서비스 제공</div>
        <div>● 홈페이지 서비스 제공, 서비스 이용 기록과 접속 빈도 분석, 서비스 이용 통계</div>
        <div>● 보안, 개인정보처리, 안전 측면에서 이용자가 안심하고 이용할 수 있는 환경 구축</div>
        <div>● 오류 또는 장애 발생 시 지원</div>
        <div>② 제품 문의</div>
        <div>
          ● 제품 문의에 대한 응답, 유지·보수 서비스 접수 및 지원, 처리 결과 통보, 제품 품질 불만 접수, 고객 응대
        </div>
        <h3>제3조 개인정보의 처리 및 보유기간</h3>
        <div>
          ① 회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보
          보유·이용기간 내에서 개인정보를 처리·보유합니다.
        </div>
        <div>② 개인정보 처리 및 보유 기간은 다음과 같습니다.</div>
        <div>1. 서비스 제공 : 재화 또는 서비스 공급 완료, 요금 결제 및 정산 완료시까지</div>

        <div>● 서비스 이용에 따른 본인 식별 및 인증 : 이용자의 동의 철회 시까지</div>
        <div>● 서비스 유지 및 개선 : 개인을 특정할 수 없는 비식별화된 가명정보만 공유</div>
        <div>다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료 시까지</div>
        <div>보유근거 : 관련 법령</div>
        <div>● 대금결제 및 재화 등의 공급에 관한 기록 : 5년</div>
        <div>● 계약 또는 청약철회 등에 관한 기록 : 5년</div>
        <div>● 표시/광고에 관한 기록 : 6개월</div>
        <div>● 소비자 불만 또는 분쟁처리에 관한 기록 : 3년</div>
        <div>2. 제품 문의 : 민원처리 완료 후 3년</div>
        <h3>제4조 개인정보의 제3자 제공</h3>
        <div>
          회사는 정보주체의 사전 동의 없이 개인정보를 제3자에게 제공하지 않습니다. 단, 법률에 특별한 규정이 있거나
          법령상 의무를 준수하기 위하여 불가피한 경우 등 개인정보 보호법 제17조에 해당하는 경우에 한하여 개인정보를
          제3자에게 제공할 수 있습니다.
        </div>
        <h3>제5조 개인정보처리의 위탁</h3>
        <div>
          ① 회사는 개인정보 보호법 제32조에 따라 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를
          위탁하고 있습니다.
        </div>
        <table>
          <thead>
            <tr>
              <th>수탁업체</th>
              <th>위탁업무내용</th>
              <th>위탁정보</th>
              <th>보유기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Google analytics</td>
              <td>서비스 이용 및 통계 분석</td>
              <td>당사웹사이트 이용자의 행태정보</td>
              <td>서비스 회원 탈퇴 및 이용계약 종료 시(단,관련법령에 따라 별도 보관되는 정보는 예외)</td>
            </tr>
          </tbody>
        </table>
        <div>
          ② 회사는 위탁계약 체결 시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적 ∙
          관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리 ∙ 감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에
          명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
        </div>
        <div>
          ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
        </div>
        <h3>제6조 정보주체와 법정대리인의 권리·의무 및 그 행사방법</h3>
        <div>① 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.</div>
        <div>● 개인정보의 열람 요구</div>
        <div>● 오류 등이 있을 경우 정정 요구</div>
        <div>● 삭제 요구</div>
        <div>● 처리 정지 요구</div>
        <div>
          ② 제1항에 따른 권리 행사는 회사에 대해 서면, 전자우편 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이
          조치하겠습니다.
        </div>
        <div>
          ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이
          경우 개인정보보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
        </div>
        <div>
          ④ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우 회사는 정정 또는 삭제를 완료할 때까지 당해
          개인정보를 이용하거나 제공하지 않습니다.
        </div>
        <div>
          ⑤ 회사는 정보주체 또는 대리인이 제1항에 따른 권리를 행사한 경우 요구를 한 자가 본인이거나 정당한 대리인인지를
          확인합니다.
        </div>
        <h3>제7조(개인정보의 파기)</h3>
        <div>
          회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를
          파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
        </div>
        <div>파기절차</div>
        <div>
          회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
        </div>
        <div>2. 파기방법</div>
        <div>
          전자적 파일 형태로 기록 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 이용하여 파기하고, 종이 문서에
          기록 저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
        </div>
        <h3>제8조(개인정보의 안정성 확보조치)</h3>
        <div>회사는 개인정보의 안정성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</div>
        <div>1. 관리적 보호조치</div>
        <div>내부관리계획 수립 및 시행, 정기적 직원 교육 등</div>
        <div>2. 기술적 보호조치</div>
        <div>개인정보처리시스템의 접근권한 관리, 접근통제시스템 설치, 개인정보의 암호화, 보안프로그램 설치</div>
        <div>3. 물리적 보호조치</div>
        <div>자료 보관 장소 출입통제</div>
        <div>가명정보의 경우, 상기 내용에 더하여 아래의 조치를 취하고 있습니다.</div>
        <div>1. 가명정보와 추가정보의 분리 보관</div>
        <div>2. 가명정보와 추가정보에 대한 접근 권한의 분리</div>
        <div>3. 아래 사항을 포함하는 가명정보 처리 관련 기록 보관</div>
        <div>● 가명정보 처리의 목적</div>
        <div>● 가명처리한 개인정보의 항목</div>
        <div>● 가명정보의 이용내역</div>
        <div>● 제3자 제공 시 제공받는 자</div>
        <div>● 그 밖에 가명정보의 처리 내용을 관리하기 위하여 보호위원회가 필요하다고 인정하여 고시하는 사항</div>
        <h3>제9조(개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)</h3>
        <div>
          ① 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를
          사용합니다.
        </div>
        <div>
          ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며
          이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
        </div>
        <div>
          ● 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속
          여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
        </div>
        <div>
          ● 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 [도구] &gt; [인터넷 옵션] &gt; [개인정보] 메뉴의 옵션 설정을
          통해 쿠키 저장을 거부 할 수 있습니다.
        </div>
        <div>● 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</div>
        <h3>제10조 (개인정보 보호책임자)</h3>
        <div>
          ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제
          등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
        </div>
        <div>개인정보 보호책임자</div>
        <div>성명 : 박은식</div>
        <div>직책 : Co-founder, CEO</div>
        <div>이메일 : espark@teamelysium.kr</div>
        <div>개인정보 보호 담당부서</div>
        <div>부서명 : 경영팀</div>
        <div>담당자 : 박은식</div>
        <div>연락처 : 01047094788</div>
        <div>
          ② 정보주체는 팀엘리시움의 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에
          관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이
          답변 및 처리해드릴 것입니다.
        </div>
        <h3>제11조(개인정보 열람청구)</h3>
        <div>
          정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 회사는
          정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
        </div>
        <div>개인정보 열람청구 접수 ∙ 처리부서</div>
        <div>부서명 : 경영팀</div>
        <div>담당자 : 박은식</div>
        <div>연락처 : 01047094788</div>
        <h3>제12조(권익침해 구제방법)</h3>
        <div>
          정보주체는 개인정보침해에 대한 신고나 상담이 필요한 경우 팀엘리시움 개인정보 보호 담당부서뿐만 아니라 다음
          기관 등에 문의할 수 있습니다.
        </div>
        <div>● 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</div>
        <div>● 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</div>
        <div>● 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</div>
        <div>● 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</div>
        <h3>제13조(개인정보 처리방침 변경)</h3>
        <div>① 이 개인정보처리방침은 2022년 5월 1일부터 적용됩니다.</div>
      </div>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 220px;

  div {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
  }

  h1 {
    text-align: center;
    color: #000;
    margin-bottom: 60px;
    line-height: normal;
  }

  h3 {
    color: #000;
    margin-bottom: 3px;
  }

  table {
    border-collapse: collapse;
    text-align: left;
    line-height: 1.5;
    margin: 20px 10px;
    color: #000;
  }

  th,
  td {
    width: 350px;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #ccc;
    vertical-align: top;
    padding: 10px;
  }

  @media screen and (max-width: 850px) {
    padding: 80px;
  }
  @media screen and (max-width: 450px) {
    padding: 40px;
  }
`;
export default Policy;
