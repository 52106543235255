import styled from "styled-components";
import certificate from "../images/2_ic_certificate.png";
import measurement from "../images/2_ic_measurement_en.png";

const SectionSecond = () => {
  return (
    <>
      <Root>
        <Wrap>
          <Box>
            <CertificateImage src={certificate} alt="폼체커 의료기기 인증" />
            <Title>의료기기 인증</Title>
            <Content>
              2등급 운동성 시험평가장치(A30130.01) 항목으로 <br /> 의료기기 인증 받은 제품입니다.
            </Content>
          </Box>
          <Box>
            <MeasurementImage src={measurement} alt="폼체커 PACS(의학영상정보시스템) 지원" />
            <Title>PACS(의학영상정보시스템) 지원</Title>
            <Content>
              측정 결과 리포트를 어디서든 확인 가능하도록 <br /> PACS 전송 기능을 지원합니다.
            </Content>
          </Box>
        </Wrap>
      </Root>

      <Line />
    </>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  padding: 69px 0;

  @media screen and (max-width: 820px) {
    padding: 50px 0;
  }
  @media screen and (max-width: 450px) {
    padding: 70px 0;
  }
`;

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 140px;

  @media screen and (max-width: 820px) {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  @media screen and (max-width: 450px) {
    gap: 115px;
  }
`;

const Box = styled.div`
  text-align: center;
`;

const CertificateImage = styled.img`
  width: 100px;
  height: 100px;
`;

const MeasurementImage = styled.img`
  width: 179px;
  height: 108px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 800;
  margin-top: 20px;

  @media screen and (max-width: 820px) {
    font-size: 16px;
  }
`;

const Content = styled.div`
  max-width: 420px;
  font-size: 16px;
  line-height: 26px;
  margin-top: 10px;

  @media screen and (max-width: 820px) {
    font-size: 14px;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 60px;
  background-color: #fff;

  @media screen and (max-width: 820px) {
    height: 50px;
  }
`;

export default SectionSecond;
