import React, { useEffect } from "react";
import Modal from "./Modal";
import styled from "styled-components";

const Wrap = styled.div`
  width: 500px;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 600px;
    height: 850px;
  }

  @media (max-width: 480px) {
    width: 300px;
    height: 600px;
  }
`;

const WebFormModal = ({ onClose }) => {
  useEffect(() => {
    const currentScript = document.createElement("script");
    const scriptElement = document.createElement("script");

    scriptElement.onload = () => {
      window.SmFormSettings.loadForm();
    };
    scriptElement.id = "loadFormScript";
    scriptElement.src = "https://salesmap.kr/web-form-loader-v3.js";

    currentScript.appendChild(scriptElement);
    document.getElementById("salesmap-web-form").appendChild(currentScript);

    return () => {
      currentScript.remove();
    };
  }, []);

  return (
    <Modal onClose={onClose}>
      <Wrap id="salesmap-web-form" data-web-form="https://salesmap.kr/web-form/a99e370d-8d30-4f51-b103-5295856a63de" />
    </Modal>
  );
};

export default WebFormModal;
