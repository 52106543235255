import { useRef } from "react";

import Header from "./Header";
import Cover from "./Cover";
import TopButton from "./TopButton";
import SectionFirst from "./SectionFirst";
import SectionSecond from "./SectionSecond";
import SectionThird from "./SectionThird";
import Video from "./Video";
import Tag from "./Tag";
import Contact from "./Contact";
import Footer from "./Footer";

function App() {
  const focusContact = useRef(null);
  const focusVideo = useRef(null);

  const videoScrollTo = () => {
    window.scrollTo({ top: focusVideo.current.offsetTop - 200, behavior: "smooth" });
  };

  const contactScrollTo = () => {
    focusContact.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Header contactScrollTo={contactScrollTo} />
      <Cover videoScrollTo={videoScrollTo} contactScrollTo={contactScrollTo} />
      <TopButton />
      <SectionFirst />
      <SectionSecond />
      <SectionThird />
      <Video ref={focusVideo} />
      <Tag />
      <Contact ref={focusContact} />
      <Footer />
    </>
  );
}

export default App;
