import styled from "styled-components";
import React from "react";

const Contact = React.forwardRef((props, ref) => {
  return (
    <Root ref={ref}>
      <Coulumn>
        <Href href="https://teamelysium.kr/" target="_blank" rel="noopener noreferrer">
          회사 홈페이지
        </Href>
        <Href href="https://www.pomchecker.com/blog/" target="_blank" rel="noopener noreferrer">
          블로그 바로가기
        </Href>
      </Coulumn>

      <Coulumn>
        <Content>
          고객센터 및 제품 문의
          <a target="_blank" href="tel:1668-0491">
            <span>1668-0491</span>
          </a>
        </Content>
        <Content>
          이메일
          <a href="mailto:contact@teamelysium.kr" target="_blank" rel="noopener noreferrer">
            <span>contact@teamelysium.kr</span>
          </a>
        </Content>
        <Content>
          카카오톡
          <a href="https://pf.kakao.com/_mQxaiC" target="_blank" rel="noopener noreferrer">
            <span>@pomchecker</span>
          </a>
        </Content>
      </Coulumn>
    </Root>
  );
});

const Root = styled.div`
  width: 800px;
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: baseline;
  justify-content: left;
  padding: 60px 0;
  margin: 0 auto;

  @media screen and (max-width: 850px) {
    width: 100%;
    max-width: 600px;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    gap: 40px;
    flex-direction: column;
    align-items: baseline;
    padding: 50px 0 0 30px;
  }
`;

const Content = styled.div`
  color: #000000;
  font-size: 16px;

  span {
    color: #000000;
    font-size: 16px;
    font-weight: 800;
    margin-left: 10px;
  }

  a {
    color: #000000;
    text-decoration-line: none;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  @media screen and (max-width: 450px) {
    font-size: 14px;
    span {
      font-size: 14px;
    }
  }
`;

const Coulumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Href = styled.a`
  color: #000000;
  font-size: 16px;
  font-weight: 800;
  text-decoration-line: none;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  @media screen and (max-width: 450px) {
    font-size: 14px;
  }
`;

export default Contact;
