import styled from "styled-components";

const Tag = () => {
  return (
    <Root>
      <Title>#POM CHECKER TAGS</Title>
      <Wrap>
        <Row>
          <HashTag>#폼체커</HashTag>
          <HashTag>#체형측정기</HashTag>
          <HashTag>#체형진단기</HashTag>
          <HashTag>#체형분석기</HashTag>
          <HashTag>#체형변화</HashTag>
        </Row>
        <Row>
          <HashTag>#자세진단기</HashTag>
          <HashTag>#자세측정기</HashTag>
          <HashTag>#운동추천</HashTag>
          <HashTag>#관절가동범위</HashTag>
          <HashTag>#의료기기</HashTag>
        </Row>
      </Wrap>
    </Root>
  );
};

const Root = styled.div`
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 20px;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: 820px) {
    width: 500px;
    gap: 0;
  }
  @media screen and (max-width: 450px) {
    width: 300px;
    gap: 0;
  }
`;

const HashTag = styled.div`
  height: 36px;
  border-radius: 20px;
  color: rgba(34, 46, 56, 0.8);
  border: 2px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 5px 20px;
  margin: 0 10px;

  @media screen and (max-width: 820px) {
    margin: 5px;
  }
  @media screen and (max-width: 450px) {
    font-size: 12px;
    padding: 9px 10px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  &:first-of-type {
    ${HashTag}:nth-child(odd) {
      background: #ffffff;
    }
  }

  &:last-of-type {
    ${HashTag}:nth-child(even) {
      background: #ffffff;
    }
  }
`;

export default Tag;
