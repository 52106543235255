import styled from "styled-components";
import { useEffect } from "react";

const Modal = ({ children, onClose }) => {
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => (document.body.style = `overflow: auto`);
  }, []);

  return (
    <>
      <Dim onClick={onClose} />
      <Content>{children}</Content>
    </>
  );
};

export const Dim = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  z-index: 98;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  z-index: 99;
`;

export const Iframe = styled.iframe`
  border: none;

  @media screen and (max-width: 850px) {
    width: 660px;
    height: 400px;
  }

  @media screen and (max-width: 450px) {
    width: 330px;
    height: 180px;
  }
`;

export default Modal;
